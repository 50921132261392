<template>
  <div class="partner-list w-full">
    <div class="mb-4">
      <h5 class="text-xl lg:text-2xl font-semibold">Partners</h5>
    </div>

    <div
      class="partner-list-wrapper p-4 border border-solid mb-2"
      v-for="(partner, i) in topic.page.partners"
      :key="i"
    >
      <div class="flex items-center justify-between flex-col sm:flex-row">
        <img class="sm:hidden py-5" :src="partner.icon"/>
        <div class="pr-5 w-full">
          <h5 class="text-xl lg:text-2xl font-semibold">{{ partner.label }}</h5>
          <h5 class="text-base mt-3 text-gray-800 leading-normal">{{ partner.description }}</h5>
          <div
            class="mt-3 partner-list-wrapper-benefits p-4">
            <h5 class="text-base text-black leading-normal mb-2">Your benefits</h5>
            <span v-html="partner.benefits"></span>
          </div>
        </div>
        <div class="sm:block hidden w-1/6 text-center">
          <img :src="partner.icon"/>
          <vs-button
            @click="goTo(partner.value)"
            class="mt-4 flex items-center justify-center w-full py-3">
            Learn more &nbsp;
            <vs-icon
              icon="arrow_right_alt"
              color="rgba(255,255,255)">
            </vs-icon>
          </vs-button>
        </div>
        <vs-button
          @click="goTo(partner.value)"
          class="sm:hidden mt-4 flex items-center justify-center w-full py-3">
          Learn more &nbsp;
          <vs-icon
            icon="arrow_right_alt"
            color="rgba(255,255,255)">
          </vs-icon>
        </vs-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    topic: {
      type: Object,
      required: true,
      default: () => ({
        page: {
          partners: []
        },
      }),
    },
  },
  methods: {
    goTo(partner) {
      const userType = this.$route.path.split('/')[1]

      this.$router.push({
        name: `${userType}-partner-resources`,
        params: {
          topic: this.topic.value,
          brand: partner
        }
      });
    }
  },
}
</script>

<style lang="scss">
.partner-list {
  &-wrapper {
    border-color: #e3ddd7;
    border-radius: 0.5rem;

    .vs-button-text {
      display: flex;
      align-items: center;
    }

    &-benefits {
      background: #EBF7F4;
      border-left: 3px solid #84CDB8;

      span {
        ul {
          list-style: disc;
          padding: 0 20px;
        }

        ol {
          list-style: auto;
          padding: 0 20px;
        }
      }
    }
  }
}
</style>