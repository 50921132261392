<template>
  <div class="topic w-full">
    <!-- navigation -->
    <vs-breadcrumb
      class="block mb-2 resources-breadcrumb"
      :items="populateBreadcrumbs"
    ></vs-breadcrumb>

    <div class="topic-wrapper flex flex-col items-center space-y-6">
      <!-- banner -->
      <banner :settings="bannerSettings"/>

      <!-- divider -->
      <vs-divider  />

      <!-- resources -->
      <related-resources
        :resources="resources"
        :title="title"/>

      <!-- divider -->
      <vs-divider  />

      <!-- partners -->
      <partner-list
        :topic="currentTopic"></partner-list>

      <!-- divider -->
      <vs-divider  />

      <!-- care help -->
      <care-help  />
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import Banner from "./resources/Banner.vue";
import RelatedResources from "./resources/RelatedResources.vue";
import PartnerList from "./resources/PartnerList.vue";
import CareHelp from "./resources/CareHelp.vue";

export default {
  components: {
    Banner,
    RelatedResources,
    PartnerList,
    CareHelp
  },
  data() {
    return {
      pagination: {
        limit: 100,
        page: 1
      },
    }
  },
  methods: {
    ...mapActions("resourceHub", ["searchResources"]),
    ...mapActions("admin", ["getSettingsByKey"]),
    ...mapMutations("resourceHub", ["MUTATE_RESOURCE_JSON_SETTING"]),

    async init() {
      try {
        this.$vs.loading();
        const { topic } = this.$route.params
        const { data } = await this.getSettingsByKey("resourceHubSetting")
        
        let formattedValue = {
          main_banner: {
            header: "",
            subHeader: "",
            image: ""
          },
          first_section: {
            header: "",
            tag: ""
          },
        }

        if (Object.keys(JSON.parse(data.data.value)).length) {
          formattedValue = JSON.parse(data.data.value)
        }

        this.MUTATE_RESOURCE_JSON_SETTING(formattedValue)

        // fetch resources
        await this.searchResources({
          ...this.pagination,
          tags: topic
        })
      } catch (error) {
        console.log(`Error fetching topic details: ${error}`)
      } finally {
        this.$vs.loading.close()
      }
    },
  },
  computed: {
    currentTopic () {
      const { popular_categories } = this.$store.state.resourceHub.settings
      const { topic } = this.$route.params

      return (popular_categories || []).find(i => i.value === topic);
    },
    populateBreadcrumbs () {
      const userType = this.$route.path.split('/')[1]

      return [
        {
          title: 'Resources',
          url: `/${userType}/resources`
        },
        {
          title: `${this.$route.params.topic}`,
          active: true,
        },
      ]
    },
    bannerSettings() {
      const currentTopic = this.currentTopic

      if (!currentTopic) return {}

      return {
        header: currentTopic.page.header,
        subHeader: currentTopic.page.subHeader,
        image: currentTopic.page.image,
      }
    },
    resources() {
      return this.$store.state.resourceHub.resources.searchResults.results;
    },
    title() {
      const currentTopic = this.currentTopic

      if (!currentTopic) return ''

      return currentTopic.page.text || ''
    }
  },
  created() {
    this.init();
  }
}
</script>

<style lang="scss">
.topic {
  .resources-breadcrumb {
    .vs-breadcrumb--link {
      color: rgba(14, 67, 51, 1);
    }
    .vs-active {
      .vs-breadcrumb--text {
        color: rgba(37, 48, 44, 0.65);
      }
    }
  }

  .vs {
    &-divider {
      margin: 0;
    }
  }
}
</style>